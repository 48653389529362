import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import "gatsby-plugin-google-fonts"

import SEO from "../components/seo"
import "./resume.scss"
import LinkedInLogo from "../components/linkedin_logo"
import Headshot from "../components/headshot"

const Sidebar = () => {
  return (
    <div className={"sidebar_container"}>
      <div className={"name"}>
        <h1 className={"thin"}>Branden</h1>
        <h1>Bonner</h1>
      </div>
      <div className={"headshot"}>
        <Headshot />
      </div>
      <div className={"about_me"}>
        <h2 className={"readable"}>About Me</h2>
        <p className={"large"}>
          Student of Chattahoochee Technical College seeking an intern position
          as a Web Developer. My front end framework of choice is React. My back
          end experience consists of Rails, .NET, Python, and Java.
        </p>
      </div>
      <div className={"contact_container"}>
        <div className={"sidebar_btn-container"}>
          <a href="mailto: branden.bonner@gmail.com" className={"sidebar_btn"}>
            <i className={"material-icons"}>email</i>
            <p className={"light"}>branden.bonner@gmail.com</p>
          </a>
        </div>
        <div className={"sidebar_btn_container"}>
          <a href="tel:404-647-3338" className={"sidebar_btn"}>
            <i className={"material-icons"}>phone</i>
            <p className={"light"}>(404) 647-3338</p>
          </a>
        </div>
        <div className={"sidebar_btn_container"}>
          <Link to="/" className={"sidebar_btn"}>
            <i className={"material-icons"}>public</i>
            <p className={"light"}>branden.bonner.bio</p>
          </Link>
        </div>
        <div className={"sidebar_btn_container"}>
          <Link
            to={"https://linkedin.com/in/branden-bonner-69185245"}
            className={"sidebar_btn"}
          >
            <div style={{ width: "2rem" }} className={"logo"}>
              <LinkedInLogo />
            </div>
            <p className={"light"}>linkedin.com/in/branden-bonner-69185245</p>
          </Link>
        </div>
      </div>
    </div>
  )
}

const Resume = () => {
  const data = useStaticQuery(graphql`
    query siteTitleQueryAndSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
      style={{
        // margin: `0 auto`,
        // height: `200vh`,
        // padding: `0 1.0875rem 1.45rem`,
        display: `flex`,
        background: `linear-gradient(180deg, rgba(18,29,58,1) 0%, rgba(179,54,86,1) 100%)`,
        justifyContent: `center`,
      }}
    >
      <main className={"resume"}>
        <SEO title="Resume" />
        <Sidebar />
        <div className={"main"}>
          <div
            className={
              "section employment"
            }
          >
            <div className={"section_heading"}>
              <div className={"heading"}>
                <h2 className={"bold"}>Employment</h2>
              </div>
              <hr />
            </div>
            <div className={"section_body column"}>
              <div className={"body_title"}>
                <h3 className={"black"}>CAD Technician</h3>
                <h3 className={"light"}>(Contract)</h3>
              </div>
              <h4 className={"light"}>Clean Water Solutions, LLC</h4>
              <p>
                Create electronic design plans for building wastewater treatment
                plants using computer software. Work with architects and
                professional draftsmen to turn blueprints and technical drawings
                into 2D and 3D computer models.
              </p>
              <div className={"body_title"}>
                <h3 className={"black"}>Music Director / Instrumentalist</h3>
                <h3 className={"light"}>(2018 - Present)</h3>
              </div>
              <h4 className={"light"}>Greater Fellowship Baptist Church</h4>
              <p>
                Responsible for specializing in playing a variety of music
                instruments with the purpose of leading worship. Composing
                original music or perform covers. Perform music for services in
                the church, including functions such as weddings and funerals.
                Plan out what music to play and spend considerable time
                practicing. Accompany other musicians in addition to leading
                congregational singing. Participate in bands, choirs, or play
                independently.
              </p>
              <div className={"body_title"}>
                <h3 className={"black"}>Revit Technician</h3>
                <h3 className={"light"}>(2018 - 2019)</h3>
              </div>
              <h4 className={"light"}>McVeigh & Mangum Engineering, Inc.</h4>
              <p>
                Incorporate all of the functions, components, construction
                procedures and maintenance protocols into one CAD design. This
                is essentially pulling together all the information from the
                different aspects of the project and presenting it in one
                design. Create design plans for building commercial buildings
                using Revit. Work together with architects and professional
                engineers to turn blueprints and technical drawings into
                computer models and presentable construction documents.
              </p>
              <div className={"body_title"}>
                <h3 className={"black"}>Church Keyboardist</h3>
                <h3 className={"light"}>(2014 - 2017)</h3>
              </div>
              <h4 className={"light"}>
                Faith in the Word Full Gospel Fellowship
              </h4>
              <p style={{ paddingBottom: "0" }}>
                Accompany other musicians in addition to leading congregational
                singing. Participate in bands, choirs, or play independently.
                Perform music for services in the church.
              </p>
            </div>
          </div>
          <div
            className={
              "section hobbies"
            }
          >
            <div className={"section_heading"}>
              <div className={"heading"}>
                <h2 className={"bold"}>Hobbies</h2>
              </div>
              <hr />
            </div>
            <div className={"section_body row"}>
              <div className={"hobby"}>
                <i className={"material-icons"}>headset</i>
                <h5>Music</h5>
              </div>
              <div className={"hobby"}>
                <i className={"material-icons"}>code</i>
                <h5>Coding</h5>
              </div>
              <div className={"hobby"}>
                <i className={"material-icons"}>carpenter</i>
                <h5>Carpentry</h5>
              </div>
              <div className={"hobby"}>
                <i className={"material-icons"}>videogame_asset</i>
                <h5>Gaming</h5>
              </div>
              <div className={"hobby"}>
                <i className={"material-icons"}>photo_camera</i>
                <h5>Photography</h5>
              </div>
              <div className={"hobby"}>
                <i className={"material-icons"}>design_services</i>
                <h5>Design</h5>
              </div>
            </div>
          </div>
          <div
            className={
              "section education"
            }
          >
            <div className={"section_heading"}>
              <div className={"heading"}>
                <h2 className={"bold"}>Education</h2>
              </div>
              <hr />
            </div>
            <div className={"section_body column"}>
              <div className={"body_title"}>
                <h3 className={"black"}>AAS Computer Programming</h3>
                <h3 className={"light"}>(2020)</h3>
              </div>
              <h4 className={"light"}>Chattahoochee Technical College</h4>
              <div className={"body_title"}>
                <h3 className={"black"}>BA Applied Computer Science</h3>
                <h3 className={"light"}>(2021)</h3>
              </div>
              <h4 className={"light"}>Kennesaw State University</h4>
            </div>
          </div>
          <div
            className={
              "section coursework"
            }
          >
            <div className={"section_heading"}>
              <div className={"heading"}>
                <h2 className={"bold"}>Coursework</h2>
              </div>
              <hr />
            </div>
            <div
              style={{ display: "grid", gridTemplateRows: "repeat(7, 1fr)" }}
              className={"section_body column"}
            >
              <div>
                <div className={"body_title"}>
                  <h3 className={"black"}>Java Programming Principles</h3>
                </div>
                <h4 className={"light"}>Java, Object-Oriented Programming</h4>
              </div>
              <div>
                <div className={"body_title"}>
                  <h3 className={"black"}>Programming with .NET Framework</h3>
                </div>
                <h4 className={"light"}>Java, Android Studio</h4>
              </div>
              <div>
                <div className={"body_title"}>
                  <h3 className={"black"}>
                    Data Structures and Database Applications
                  </h3>
                </div>
                <h4 className={"light"}>
                  Structured Query Language, ASP.NET Web
                </h4>
              </div>
              <div>
                <div className={"body_title"}>
                  <h3 className={"black"}>Web Development</h3>
                </div>
                <h4 className={"light"}>HTML5, CSS3, JavaScript</h4>
              </div>
              <div>
                <div className={"body_title"}>
                  <h3 className={"black"}>iOS Development</h3>
                </div>
                <h4 className={"light"}>Objective-C, Swift</h4>
              </div>
              <div className={"body_title"}>
                <h3 className={"black"}>Python</h3>
              </div>
              {/*<h4 className={'light'}></h4>*/}
              <div className={"body_title"} style={{ alignContent: "start" }}>
                <h3 className={"black"}>MySQL</h3>
              </div>
              {/*<h4 className={'light'}></h4>*/}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Resume
